import { onLocal } from "src/utils/functions"

import type {
  ApiRequest,
  AuthRequest,
  OAuthAxiosRequestHeaders,
} from "src/api/apiInterfaces"
import type { User } from "./interfaces"

const UserApi = (
  auth: AuthRequest,
  baseURL: string,
  getEndpoint: CallableFunction
) => {
  return {
    get: async (
      headers: OAuthAxiosRequestHeaders,
      id: string | null = null
    ) => {
      const endpoint = onLocal() && id !== null ? "impersonated" : "user"

      return await auth.get<User>(getEndpoint(endpoint, id), baseURL, headers)
    },
  }
}

const UserRequest = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    acknowledge: async () =>
      await request.post(getEndpoint("acknowledgeCaptainNotice"), {}),
  }
}

export { UserApi, UserRequest }
