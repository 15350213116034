import axios, { type AxiosResponse } from "axios"
import type {
  OAuthAxiosRequestHeaders,
  ApiRequest,
  ParamConfig,
} from "./apiInterfaces"
import { onDev } from "../utils/functions"
import SearchApi from "./search/SearchApi"
import SettingsApi from "./settings/SettingsApi"
import RulesApi from "./rules/RulesApi"
import PilotApi from "./pilot/PilotApi"
import { UserApi, UserRequest } from "./user/UserApi"
import TokenApi from "./token/TokenApi"
import PilotsApi from "./pilots/PilotsApi"
import MailingListApi from "./mailingList/MailingListApi"
import CompletedEvaluationsApi from "./completedEvaluations/CompletedEvaluationsApi"
import EvaluationsApi from "./evaluations/EvaluationsApi"
import FlightsApi from "./flights/FlightsApi"
import PairingsApi from "./pairings/PairingsApi"
import UserBasesApi from "./userBases/UserBasesApi"
import CaptainApi from "./captain/CaptainApi"
import CaptainsApi from "./captains/CaptainsApi"
import { store } from "src/store"
import type { User } from "./user/interfaces"

const signPage = `${process.env["REACT_APP_URL"] ?? ""}${
  process.env["AUTH_SIGN_PATH"] ?? ""
}`

function getEndpoint(name: string, id: number | string | null = null) {
  let url = ""

  switch (name) {
    case "flights":
      url = process.env["API_FLIGHTS_ENDPOINT"] ?? ""
      break
    case "evaluations":
      url = process.env["API_EVALUATIONS_ENDPOINT"] ?? ""
      break
    case "newEvaluation":
      url = process.env["API_NEW_EVALUATION_ENDPOINT"] ?? ""
      break
    case "completed_evaluations":
      url = process.env["API_COMPLETED_EVALUATIONS_ENDPOINT"] ?? ""
      break
    case "token":
      url = process.env["AUTH_TOKEN_ENDPOINT"] ?? ""
      break
    case "user":
      url = process.env["AUTH_USER_ENDPOINT"] ?? ""
      break
    case "acknowledgeCaptainNotice":
      url = process.env["API_ACKNOWLEDGE_NOTICE"] ?? ""
      break
    case "impersonated":
      url = process.env["AUTH_IMPERSONATED_ENDPOINT"] ?? ""
      break
    case "pilot":
      url = process.env["API_PILOT_ENDPOINT"] ?? ""
      break
    case "pilots":
      url = process.env["API_PILOT_LIST_ENDPOINT"] ?? "" // PILOTS_ENDPOINT
      break
    case "pilotsScoreCard":
      url = process.env["API_PILOTS_SCORECARD_ENDPOINT"] ?? "" // PILOTS_ENDPOINT
      break
    case "pilotSummary":
      url = process.env["API_PILOT_SUMMARY_ENDPOINT"] ?? ""

      if (!onDev()) {
        const pid = id ?? ""
        url = `${url}?pilotId=${pid}`
        id = null
      }

      break
    case "mailingList":
      url = process.env["API_MAILING_LIST_ENDPOINT"] ?? ""
      break
    case "rules":
      url = process.env["API_RULES_ENDPOINT"] ?? ""
      break
    case "settings":
      url = process.env["API_SETTINGS_ENDPOINT"] ?? ""
      break
    case "search":
      url = process.env["API_PILOTS_SEARCH_ENDPOINT"] ?? ""
      break
    case "userBases":
      url = process.env["API_USER_BASES_ENDPOINT"] ?? ""
      break
    case "pairings":
      url = process.env["API_PAIRINGS_ENDPOINT"] ?? ""
      break
    case "captain":
      url = process.env["API_CAPTAIN_ENDPOINT"] ?? ""
      break
    case "captains":
      url = process.env["API_CAPTAINS_LIST_ENDPOINT"] ?? ""
      break
    case "captain_evaluations":
      url = process.env["API_CAPTAIN_EVALUATIONS_ENDPOINT"] ?? ""
      break
    case "search_pairings":
      url = process.env["API_SEARCH_PAIRINGS"] ?? ""
      break
    case "saveEvaluation":
      url = process.env["API_SAVE_EVALUATION"] ?? ""
      break
  }

  if (id !== null) {
    url = `${url}/${id}`
  }

  return url
}

const localhostURL = "http://localhost:3000"
const baseURL = process.env["API_URL"] ?? localhostURL
const authURL = onDev() ? localhostURL : baseURL
const jwt = localStorage.getItem("token") ?? ""

const API = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
})

// Add a request interceptor
API.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  async function (error) {
    // Do something with request error
    return await Promise.reject(error)
  }
)

// Add a response interceptor
API.interceptors.response.use(
  async function (response) {
    if (onDev()) {
      return await new Promise(function (resolve, reject) {
        setTimeout(() => {
          resolve(response)
          reject(new Error("Error!"))
        }, 1000)
      })
    } else {
      return response
    }
  },
  async function (error) {
    if (
      error.response !== undefined &&
      [401, 403].includes(error.response.status)
    ) {
      if (window.location.href !== signPage) {
        sessionStorage.setItem("redirectAfterLogin", window.location.href)
        window.location.href = signPage
      }
      return false
    } else {
      return await Promise.reject(error)
    }
  }
)

const responseBody = <T>(response: AxiosResponse<T>) => response.data

let impersonated = false
let stateUser: User | null = null

const listener = () => {
  const state = store.getState()
  impersonated = state.impersonated
  stateUser = state.user
}

store.subscribe(listener)

const request: ApiRequest = {
  get: async <T>(
    url: string,
    config: ParamConfig = { params: new URLSearchParams() }
  ) => {
    if (impersonated && stateUser !== null)
      config.params.append("impersonate", stateUser.uid)

    return await API.get<T>(url, config).then(responseBody)
  },
  post: async <T>(url: string, body: unknown) => {
    const params = new URLSearchParams()
    if (impersonated && stateUser !== null)
      params.append("impersonate", stateUser.uid)

    return await API.post<T>(url, body, { params }).then(responseBody)
  },
  put: async <T>(url: string, body: unknown) => {
    const params = new URLSearchParams()
    if (impersonated && stateUser !== null)
      params.append("impersonate", stateUser.uid)

    return await API.put<T>(url, body, { params }).then(responseBody)
  },
  delete: async <T>(
    url: string,
    config: ParamConfig = { params: new URLSearchParams() }
  ) => {
    if (impersonated && stateUser !== null)
      config.params.append("impersonate", stateUser.uid)

    return await API.delete<T>(url, config).then(responseBody)
  },
}

const auth = {
  get: async <T>(
    url: string,
    baseURL: string,
    headers: OAuthAxiosRequestHeaders
  ) => await axios.get<T>(url, { baseURL, headers }).then(responseBody),
  post: async <T>(
    url: string,
    body: unknown,
    headers: OAuthAxiosRequestHeaders
  ) => await axios.post<T>(url, body, { headers }).then(responseBody),
}

const token = TokenApi(auth, authURL, getEndpoint)
const user = UserApi(auth, baseURL, getEndpoint)

const flights = FlightsApi(request, getEndpoint)
const evaluations = EvaluationsApi(request, getEndpoint)
const completedEvaluations = CompletedEvaluationsApi(request, getEndpoint)
const mailingList = MailingListApi(request, getEndpoint)
const pilots = PilotsApi(request, getEndpoint)
const pilot = PilotApi(request, getEndpoint)
const rules = RulesApi(request, getEndpoint)
const settings = SettingsApi(request, getEndpoint)
const search = SearchApi(request, getEndpoint)
const userBases = UserBasesApi(request, getEndpoint)
const pairings = PairingsApi(request, getEndpoint)
const captain = CaptainApi(request, getEndpoint)
const captains = CaptainsApi(request, getEndpoint)
const userRequest = UserRequest(request, getEndpoint)

export {
  completedEvaluations,
  evaluations,
  flights,
  pilot,
  pilots,
  token,
  rules,
  mailingList,
  settings,
  user,
  search,
  userBases,
  pairings,
  captain,
  captains,
  userRequest,
}
