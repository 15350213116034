import React from "react"
import App from "./App"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { store } from "./store"
import { onDev } from "./utils/functions"
import "atmos-design-system/dist/css/atmos.css"
import "./App.scss"

const rootNode = document.getElementById("root")
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  rootNode
)
