import React, { useState } from "react"
import { BoxSection } from "atmos-design-system"
import { Icon } from "atmos-design-system"

const content = (
  <>
    <p>
      Please follow the rating guidelines defined below. Data integrity is
      enhanced when this rating scale is applied consistently and accurately.
    </p>

    <strong>1 - Excellent</strong>
    <p>
      Consistently performs and meets the highest of expectation. Proficiency
      regarding this item exceeds all standards.
    </p>

    <strong>2 - Above Expectations</strong>
    <p>
      Consistently strives to perform above expectation. Proficiency regarding
      this item meets standards and they are occasionally exceeded.
    </p>

    <strong>3 - Expected Performance</strong>
    <p>
      Generally performs and meets expectations. Proficiency regarding this item
      meets standards.
    </p>

    <strong>4 - Meets Minimum Standards</strong>
    <p>
      Occasionally performs below expectations. Proficiency regarding this item
      is adequate and could use improvement.
    </p>

    <strong>5 - Unsatisfactory</strong>
    <p>
      Consistently fails to perform within expectations. Proficiency regarding
      this item is not adequate and falls below standards.
    </p>
  </>
)

function GradingGuidelinesAccordion() {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <section className="atm-c-box-section ebp-foppt-c-grading-guidelines-accordion">
      <div className="atm-c-box-section__header">
        <div className="ebp-foppt-c-grading-guidelines-accordion__header">
          <h4 className="atm-c-box-section__title">Rating Guidelines:</h4>
          <div className="chevron-wrapper">
            <h4 className="atm-c-box-section__title">
              <a
                className="ebp-foppt-u-cursor-hand"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <span>
                  <Icon name={`caret-${isExpanded ? "up" : "down"}`} />
                </span>
              </a>
            </h4>
          </div>
        </div>
      </div>
      {isExpanded && <div className="atm-c-box-section__body">{content}</div>}
    </section>
  )
}

export default function GradingGuidelines({
  view = "default",
}: {
  view?: "default" | "accordion"
}) {
  if (view === "accordion") {
    return <GradingGuidelinesAccordion />
  }

  return <BoxSection title="Rating Guidelines:">{content}</BoxSection>
}
