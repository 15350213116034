import { useCallback, useReducer } from "react"
import { useDispatch } from "react-redux"
import { pilots } from "src/api"
import type { PilotsResponse } from "src/api/pilots/interfaces"
type ExtraStateActions =
  | {
      type: "loadPage"
      payload: { response: PilotsResponse; append: boolean }
    }
  | { type: "isFetching"; payload: boolean }
  | {
      type: "sortChange"
      payload: { sortColumn: keyof PilotInfo; sortDirection: "Asc" | "Des" }
    }
  | {
      type: "loadBases"
      payload: { bases: CheckboxesType[] }
    }
  | {
      type: "loadStatus"
      payload: { status: CheckboxesType[] }
    }
  | {
      type: "loadFlags"
      payload: { flags: CheckboxesType[] }
    }
  | {
      type: "setBasesStatusAndFlags"
      payload: {
        bases: CheckboxesType[]
        status: CheckboxesType[]
        flags: CheckboxesType[]
      }
    }
  | { type: "lastCall"; payload: boolean }

interface StateTypes {
  sortColumn: keyof PilotInfo
  sortDirection: "Asc" | "Des"
  page: number
  pageSize: number
  lastCall: boolean
  isFetching: boolean
  data: PilotInfo[]
  bases: CheckboxesType[]
  status: CheckboxesType[]
  flags: CheckboxesType[]
}

const INITIAL_STATE_EXTRA: StateTypes = {
  sortColumn: "dateOfHire",
  sortDirection: "Asc",
  page: 1,
  pageSize: 50,
  lastCall: false,
  isFetching: false,
  data: [],
  bases: [],
  status: [],
  flags: [],
}

function pilotListStateControls(
  state: StateTypes,
  action: ExtraStateActions
): StateTypes {
  switch (action.type) {
    case "isFetching": {
      return { ...state, [action.type]: action.payload }
    }
    case "sortChange": {
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
        sortColumn: action.payload.sortColumn,
        page: 1,
        data: [],
        isFetching: true,
      }
    }
    case "loadPage": {
      return {
        ...state,
        isFetching: false,
        data: action.payload.append
          ? [...state.data, ...action.payload.response.pilots]
          : action.payload.response.pilots,
        page: Number(action.payload.response.pagination.page) + 1,
        lastCall: action.payload.response.pilots.length < state.pageSize,
      }
    }
    case "loadBases": {
      return {
        ...state,
        bases: action.payload.bases,
      }
    }
    case "loadStatus": {
      return {
        ...state,
        status: action.payload.status,
      }
    }
    case "loadFlags": {
      return {
        ...state,
        flags: action.payload.flags,
      }
    }
    case "setBasesStatusAndFlags": {
      return {
        ...state,
        bases: action.payload.bases,
        status: action.payload.status,
        flags: action.payload.flags,
      }
    }
    case "lastCall": {
      return {
        ...state,
        lastCall: action.payload,
      }
    }
    default: {
      return { ...state }
    }
  }
}

function usePilotList() {
  const reduxDispatch = useDispatch()
  const [states, dispatch] = useReducer(
    pilotListStateControls,
    INITIAL_STATE_EXTRA
  )

  const setIsFetching = useCallback((payload: boolean) => {
    dispatch({ type: "isFetching", payload })
  }, [])

  const getParams = useCallback(
    (
      append: boolean,
      sort?: {
        column: keyof PilotInfo
        direction: "Asc" | "Des"
      },
      filters: {
        bases: CheckboxesType[] | null
        status: CheckboxesType[] | null
        flags: CheckboxesType[] | null
      } = { bases: null, status: null, flags: null }
    ) => {
      const filter = {
        bases:
          filters.bases !== null
            ? filters.bases.filter((x) => x.selected).map((x) => x.value)
            : states.bases
                .filter((item) => item.selected)
                .map((item) => item.value),
        status:
          filters.status !== null
            ? filters.status.filter((x) => x.selected).map((x) => x.value)
            : states.status
                .filter((item) => item.selected)
                .map((item) => item.value),
        flags:
          filters.flags !== null
            ? filters.flags.filter((x) => x.selected).map((x) => x.value)
            : states.flags
                .filter((item) => item.selected)
                .map((item) => item.value),
      }

      const params = new URLSearchParams()
      filter.bases.forEach((b) => {
        params.append("base", b)
      })
      filter.status.forEach((s) => {
        params.append("status", s)
      })
      filter.flags.forEach((s) => {
        params.append("flag", s)
      })

      params.append("page", append ? String(states.page) : "1")
      params.append("pageSize", String(states.pageSize))

      params.append("sortDirection", sort?.direction ?? states.sortDirection)
      params.append("sortColumn", sort?.column ?? states.sortColumn)

      return params
    },
    [states]
  )

  const loadPilots = useCallback(
    (
      append = true,
      isSuperAdmin = false,
      sort?: {
        column: keyof PilotInfo
        direction: "Asc" | "Des"
      },
      filters: {
        bases: CheckboxesType[] | null
        status: CheckboxesType[] | null
        flags: CheckboxesType[] | null
      } = { bases: null, status: null, flags: null }
    ) => {
      reduxDispatch({ type: "loader" })
      pilots
        .get(getParams(append, sort, filters))
        .then((response) => {
          dispatch({
            type: "loadPage",
            payload: { append, response },
          })
          const { bases: filterBases } = filters
          if (
            filterBases !== null &&
            response.bases.length > filterBases.length
          ) {
            const newBases: CheckboxesType[] = response.bases.map((base) => {
              const savedBase = filterBases.find((x) => x.label === base)
              return {
                label: base,
                value: base,
                selected: isSuperAdmin
                  ? false
                  : savedBase !== undefined
                  ? savedBase.selected
                  : false,
              }
            })
            dispatch({
              type: "loadBases",
              payload: { bases: newBases },
            })
          }
        })
        .catch((err) => {
          if (
            err?.code === "ERR_NETWORK" ||
            (err?.response !== undefined && err.response?.status >= 400)
          ) {
            reduxDispatch({
              type: "alert",
              payload: {
                id: "PilotList__fetchingFailed",
                display: true,
                message: "Fetching Pilot List failed",
                type: "error",
              },
            })
          }
          dispatch({
            type: "isFetching",
            payload: false,
          })
        })
        .finally(() => {
          reduxDispatch({ type: "loader" })
        })
    },
    [getParams, reduxDispatch]
  )

  const changeSorting = useCallback(
    (payload: {
      isSuperAdmin: boolean
      sortDirection: "Asc" | "Des"
      sortColumn: keyof PilotInfo
    }) => {
      dispatch({ type: "sortChange", payload })
      loadPilots(false, payload.isSuperAdmin, {
        column: payload.sortColumn,
        direction: payload.sortDirection,
      })
    },
    [loadPilots]
  )

  const setBases = useCallback((payload: { bases: CheckboxesType[] }) => {
    dispatch({ type: "loadBases", payload })
  }, [])

  const setStatus = useCallback((payload: { status: CheckboxesType[] }) => {
    dispatch({ type: "loadStatus", payload })
  }, [])

  const setFlags = useCallback((payload: { flags: CheckboxesType[] }) => {
    dispatch({ type: "loadFlags", payload })
  }, [])

  const setBasesStatusAndFlags = useCallback(
    (payload: {
      bases: CheckboxesType[]
      status: CheckboxesType[]
      flags: CheckboxesType[]
      isSuperAdmin: boolean
    }) => {
      dispatch({ type: "setBasesStatusAndFlags", payload })
      loadPilots(false, payload.isSuperAdmin, undefined, {
        bases: payload.bases,
        status: payload.status,
        flags: payload.flags,
      })
    },
    [loadPilots]
  )

  return {
    states,
    changeSorting,
    getParams,
    setIsFetching,
    loadPilots,
    setBases,
    setStatus,
    setFlags,
    setBasesStatusAndFlags,
  }
}

export default usePilotList
