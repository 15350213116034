import React, { useEffect, useState, Fragment, useCallback } from "react"
import { AccordionHeader } from "./AccordionHeaders"
import { useSelector } from "react-redux"
import {
  Accordion,
  AccordionPanel,
  ButtonGroup,
  Button,
} from "atmos-design-system"
import "./styles.scss"
import { usePilotsScorecard } from "./hooks/usePilotsScoreCard"
import type { UserState } from "src/api/user/interfaces"
import { ITEMS_TO_PAGINATE } from "src/utils/constants"
import GradingGuidelines from "src/components/Pilot/EvaluationForm/gradingGuidelines"

const PilotsScorecard = () => {
  const user = useSelector((state: UserState) => state.user)

  const { states, loadData } = usePilotsScorecard(user.uid)
  const [freeComments, setFreeComments] = useState<string[]>([])
  const [commentsToShow, setcommentsToShow] = useState(5)
  const [initialComments, setInitialComments] = useState<
    Record<string, number>
  >({})

  const renderSubCategory = (subCategory: ScorecardSubcategory) => {
    return (
      <div className="ebp-foppt-c-category-container">
        <div className="ebp-foppt-c-accordion-title">
          <label className="atm-u-typography-preset-4--bold">
            {subCategory.title}
          </label>
        </div>
        <div
          className={`ebp-foppt-c-accordion-title ${
            subCategory.comments.length === 0
              ? "ebp-foppt-c-accordion-title__disabled"
              : ""
          } `}
        >
          <label className="atm-u-typography-preset-4--bold">
            Average: {subCategory.avg}
          </label>
        </div>
      </div>
    )
  }
  const renderFreeCommentsTitle = () => {
    return (
      <div className="ebp-foppt-c-accordion-title">
        <label className="atm-u-typography-preset-4--bold">Comments</label>
      </div>
    )
  }

  const handleFreeComments = () => {
    if (
      commentsToShow + ITEMS_TO_PAGINATE <=
      states.data.evaluationComments.length
    ) {
      setcommentsToShow(commentsToShow + ITEMS_TO_PAGINATE)
    } else setcommentsToShow(states.data.evaluationComments.length)
  }

  const handleCategoryComments = (category: string, length: number) => {
    const isValid = initialComments[category] + ITEMS_TO_PAGINATE <= length
    if (isValid) {
      const updateCategoriesCount = {
        ...initialComments,
        [category]: initialComments[category] + ITEMS_TO_PAGINATE,
      }
      setInitialComments(updateCategoriesCount)
    } else {
      setInitialComments((prevState) => ({
        ...prevState,
        [category]: length,
      }))
    }
  }

  const initialSucbcategories = useCallback(() => {
    const categoryTitles = states.data.gradings
      .map((cat) => cat.subcategories.map((sub) => sub.title))
      .flatMap((item) => item)
      .reduce(
        (titleCategory, initialComments) => ({
          ...titleCategory,
          [initialComments]: ITEMS_TO_PAGINATE,
        }),
        {}
      )

    setInitialComments(categoryTitles)
  }, [states.data.gradings])

  useEffect(() => {
    initialSucbcategories()
  }, [initialSucbcategories])

  useEffect(() => {
    loadData(false)
  }, [loadData])

  return (
    <>
      <p className="ebp-foppt-evaluations-info text-red">
        Current month evaluations are available after the 10th day of the next
        month
      </p>
      <p className="ebp-foppt-evaluations-info">
        Number of Evaluations Submitted: {states.data.evaluationCount}
      </p>
      <GradingGuidelines view="accordion" />
      {states.data.gradings.length > 0 ? (
        <>
          {states.data.gradings.map((category, categoryIndex) => (
            <Fragment key={categoryIndex}>
              <AccordionHeader
                key={categoryIndex}
                title={category.title}
                titleAfter={`Average Rating: ${category.avg}`}
                isSortable={false}
              />
              {category.subcategories.map((subCategory, index) => (
                <Fragment key={index}>
                  <div className="ebp-foppt-c-accordion-container">
                    <Accordion>
                      <AccordionPanel
                        disabled={subCategory.comments.length === 0}
                        key={index}
                        titleContent={renderSubCategory(subCategory)}
                      >
                        {subCategory.comments
                          .slice(0, initialComments[subCategory.title])
                          .map((comment, index) => (
                            <Fragment key={index}>
                              <p
                                key={index}
                                className="ebp-foppt-c-container-comments"
                              >
                                {comment}
                              </p>
                            </Fragment>
                          ))}
                        {initialComments[subCategory.title] <
                          subCategory?.comments.length && (
                          <ButtonGroup className="ebp-foppt-see-more-container-btn">
                            <Button
                              text={"Load more comments"}
                              onClick={() => {
                                handleCategoryComments(
                                  subCategory.title,
                                  subCategory?.comments.length
                                )
                              }}
                            />
                          </ButtonGroup>
                        )}
                      </AccordionPanel>
                    </Accordion>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ))}

          {states.data.evaluationComments.length > 0 ? (
            <>
              <AccordionHeader title={"Free Comments"} isSortable={false} />
              <Accordion className="ebp-foppt-c-accordion-container">
                <AccordionPanel
                  titleContent={renderFreeCommentsTitle()}
                  onToggle={() => {
                    setFreeComments(states.data.evaluationComments)
                  }}
                >
                  {freeComments
                    .slice(0, commentsToShow)
                    .map((comment, index) => (
                      <p className="ebp-foppt-c-container-comments" key={index}>
                        {comment}
                      </p>
                    ))}
                  {commentsToShow < states.data.evaluationComments.length && (
                    <ButtonGroup className="ebp-foppt-see-more-container-btn">
                      <Button
                        text={"Load more comments"}
                        onClick={handleFreeComments}
                      />
                    </ButtonGroup>
                  )}
                </AccordionPanel>
              </Accordion>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <h3>There are no evaluations to display</h3>
      )}
    </>
  )
}

export default PilotsScorecard
